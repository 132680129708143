<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header" no-body>
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						WiFi
						<b-badge>{{wcListCount}}</b-badge>
					</h3>
				</template>
				<b-overlay :show="wcOverlay" rounded="lg" variant="white">
					<b-list-group flush>
						<router-link custom :to="{path: '/wifi/'+wc.id}" v-slot="{href, route, navigate, isActive, isExactActive}"
							v-for="wc in wcList"
							:key="wc.id"
						>
							<b-list-group-item :href="href" @click="navigate">
								{{wc.ssid}}
								<i class="fa fa-chevron-right mt-1 pull-right"></i>
							</b-list-group-item>
						</router-link>
						
						<router-link custom :to="{path: '/wifi/add'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-list-group-item :href="href" @click="navigate">
								Добавить новую WiFi сеть
								<i class="fa fa-plus mt-1 pull-right"></i>
							</b-list-group-item>
						</router-link>
					</b-list-group>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
//import { decl1, decl2 } from '../assets/js/scripts.js';
//import _ from 'lodash';

export default {
	name: 'Wifi',
	data: () => ({
		wcOverlay: false,
		wcList: [],
		wcListCount: 0,
	}),
	methods: {
		loadData(){
			this.wcOverlay = true;
			fetch('/api/wifi-connections')
				.then(stream => stream.json())
				.then(ret => {
					this.wcList = ret.list;
					this.wcListCount = ret.count;
					
					setTimeout(() => {
						this.wcOverlay = false;
					}, 1000);
				})
				.catch(error => console.error(error));
		},
	},
	beforeMount(){
		this.loadData();
	},
	computed: {
		model(){
			return this.getModel();
		},
	},
	components: {
		
	},
};
</script>