<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>404 - Страница не существует</h3>
		</header>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'PageNotFound',
	computed: {
		
	},
};
</script>