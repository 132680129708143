import Vue from 'vue';
import VueRouter from 'vue-router';

import {localStorageSet, localStorageGet, localStorageRemove} from './lib.js';

import Layout from './layouts/Layout.vue';

import Status from './pages/Status.vue';
import Wifi from './pages/Wifi.vue';
import WifiAdd from './pages/WifiAdd.vue';
import WifiEdit from './pages/WifiEdit.vue';
import NFCApp from './pages/NFCApp.vue';
import NFCFingerPrintApp from './pages/NFCFingerPrintApp.vue';
import RFIDApp from './pages/RFIDApp.vue';

import PageNotFound from './pages/PageNotFound.vue';

export default function(params)
{
	params["router"] = new VueRouter({
		mode: 'history',
		base: '/',
		routes:
		[
			{
				path: '/',
				redirect: '/status',
			},
			{
				path: '/',
				component: Layout,
				children: [
					{
						path: '/status',
						component: Status,
					},
					{
						path: '/wifi',
						component: Wifi,
					},
					{
						path: '/wifi/add',
						component: WifiAdd,
						props: {default: true, namespace: ["page", "WifiAdd"]},
					},
					{
						path: '/wifi/:id',
						component: WifiEdit,
						props: {default: true, namespace: ["page", "WifiEdit"]},
					},
					{
						path: '/nfcapp',
						component: NFCApp,
					},
					{
						path: '/nfcfingerprintapp',
						component: NFCFingerPrintApp,
					},
					{
						path: '/rfidapp',
						component: RFIDApp,
					},
				],
				props: {default: true, namespace: ["page", "Home"]},
			},
			{
				path: '*',
				component: Layout,
				children: [
					{
						path: '',
						component: PageNotFound,
					},
				],
				props: {default: true, namespace: ["page", "PageNotFound"]},
			},
		],
		//linkActiveClass: 'active',
		//linkExactActiveClass: 'active',
	});
	
	params["store"].registerModule(["page", "Layout"], Layout.buildStore());
	//params["store"].registerModule(["page", "Home"], Home.buildStore());
	//params["store"].registerModule(["page", "PageNotFound"], PageNotFound.buildStore());
	
	return params;
}
