<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0">RFID App</h3>
				</template>
				<b-tabs>
					<b-tab title="Cервис" active>
						<b-overlay :show="statusOverlay" rounded="lg" variant="white">
							<b-list-group class="mt-3">
								<b-list-group-item class="d-flex justify-content-between align-items-center">
									Состояние сервиса:
									<h5 class="mb-0">
										<b-badge
											:variant="isActive == null ? 'warning' : (isActive ? 'success' : 'danger')"
										>{{isActive == null ? 'Неизвестно' : (isActive ? 'Запущен' : 'Не запущен')}}</b-badge>
									</h5>
								</b-list-group-item>
								<b-list-group-item class="text-center mb-3">
									<b-button variant="info" class="mr-1" @click="setStart()">Запустить</b-button>
									<b-button variant="info" class="mr-1" @click="setRestart()">Перезапустить</b-button>
									<b-button variant="danger" @click="setStop()">Остановить</b-button>
								</b-list-group-item>
							</b-list-group>
							<div class="d-flex justify-content-between align-items-center">
								<code>service bmc-rfid-app status</code>
								<div class="my-2">
									<b-button variant="primary" @click="getStatus()">Обновить</b-button>
								</div>
							</div>
							<b-form-textarea
								id="txtStatus"
								v-model="txtStatus"
								rows="3"
								max-rows="20"
								readonly
							></b-form-textarea>
						</b-overlay>
					</b-tab>
					<b-tab title="Лог программы">
						<div class="d-flex justify-content-between align-items-center">
							<code>cat /var/log/bmc-rfid-app/app.log</code>
							<div class="my-2">
								<b-button variant="secondary" class="mr-1">Очистить</b-button>
								<b-button variant="primary">Обновить</b-button>
							</div>
						</div>
						<b-form-textarea
							id="txtAppLog"
							v-model="txtAppLog"
							rows="3"
							max-rows="20"
							readonly
						></b-form-textarea>
					</b-tab>
					<b-tab title="Лог ошибок">
						<div class="d-flex justify-content-between align-items-center">
							<code>cat /var/log/bmc-rfid-app/err.log</code>
							<div class="my-2">
								<b-button variant="secondary" class="mr-1">Очистить</b-button>
								<b-button variant="primary">Обновить</b-button>
							</div>
						</div>
						<b-form-textarea
							id="txtErrorLog"
							v-model="txtErrorLog"
							rows="3"
							max-rows="20"
							readonly
						></b-form-textarea>
					</b-tab>
					<b-tab title="Лог отправки">
						<div class="d-flex justify-content-between align-items-center">
							<code>cat {{(config.debug_log_file||'').substr(0, 1) == '/' ? config.debug_log_file : '/opt/bmc-rfid-app/'+(config.debug_log_file||'')}}</code>
							<div class="my-2">
								<b-button variant="secondary" class="mr-1">Очистить</b-button>
								<b-button variant="primary">Обновить</b-button>
							</div>
						</div>
						<b-form-textarea
							id="txtSendLog"
							v-model="txtSendLog"
							rows="3"
							max-rows="20"
							readonly
						></b-form-textarea>
					</b-tab>
					<b-tab title="Очередь">
						<div class="d-flex justify-content-between align-items-center">
							<code>cat {{(config.requests_file||'').substr(0, 1) == '/' ? config.requests_file : '/opt/bmc-rfid-app/'+(config.requests_file||'')}}</code>
							<div class="my-2">
								<b-button variant="secondary" class="mr-1">Очистить</b-button>
								<b-button variant="primary">Обновить</b-button>
							</div>
						</div>
						<b-form-textarea
							id="txtRequestsQueue"
							v-model="txtRequestsQueue"
							rows="3"
							max-rows="20"
							readonly
						></b-form-textarea>
					</b-tab>
					<b-tab title="Конфигурация">
						<b-overlay :show="configOverlay" rounded="lg" variant="white">
							<b-form @submit="onConfigSave" @reset="onConfigSetDefault">
								<div class="d-flex justify-content-between align-items-center">
									<code>cat /opt/bmc-rfid-app/conf.ini</code>
									<div class="my-2">
										<b-button variant="secondary" type="reset" class="mr-1">По умолчанию</b-button>
										<b-button variant="primary" type="submit">Сохранить</b-button>
									</div>
								</div>
								
								<b-form-group
									id="input-group-company-pkid"
									label="PKID компании:"
									label-for="input-company-pkid"
									description="Можно через запятую, если нужно несколько"
								>
									<b-form-input
										id="input-company-pkid"
										v-model="config_new.company_pkid"
										type="text"
										required
									></b-form-input>
								</b-form-group>
								
								<b-form-group
									id="input-group-debug-log-level"
									label="Уровень вывода лога программы:"
									label-for="input-debug-log-level"
								>
									<b-form-select
										id="input-debug-log-level"
										v-model="config_new.debug_log_level"
										:options="config_form.debug_log_level_options"
										required
									></b-form-select>
								</b-form-group>
								
								<b-form-group
									id="input-group-debug-log-file"
									label="Путь к файлу, где будут собираться логи GET/POST запросов:"
									label-for="input-debug-log-file"
									description="Директория должна существовать"
								>
									<b-form-input
										id="input-debug-log-file"
										v-model="config_new.debug_log_file"
										type="text"
										placeholder="Например: logs/requests.log"
										required
									></b-form-input>
								</b-form-group>
								
								<b-form-group
									id="input-group-requests-file"
									label="Путь к файлу очереди:"
									label-for="input-requests-file"
									description="Директория должна существовать"
								>
									<b-form-input
										id="input-requests-file"
										v-model="config_new.requests_file"
										type="text"
										placeholder="Например: logs/requests.queue"
										required
									></b-form-input>
								</b-form-group>
								
								<b-form-group
									id="input-group-send-api-enable"
									label="Включить отправку GET/POST запросов на сервер через API:"
									label-for="input-send-api-enable"
								>
									<b-form-select
										id="input-send-api-enable"
										v-model="config_new.send_api_enable"
										:options="config_form.bool"
										required
									></b-form-select>
								</b-form-group>
								
								<b-form-group
									id="input-group-send-api-logging"
									label="Включить логирование ответов сервера:"
									label-for="input-send-api-logging"
								>
									<b-form-select
										id="input-send-api-logging"
										v-model="config_new.send_api_logging"
										:options="config_form.bool"
										required
									></b-form-select>
								</b-form-group>
								
								<b-form-group
									id="input-group-send-api-method"
									label="Метод отправки запросов на сервер через API:"
									label-for="input-send-api-method"
								>
									<b-form-select
										id="input-send-api-method"
										v-model="config_new.send_api_method"
										:options="config_form.send_api_method_options"
										required
									></b-form-select>
								</b-form-group>
								
								<b-form-group
									id="input-group-send-api-host"
									label="Адрес сайта с API функцией:"
									label-for="input-send-api-host"
									description="Без указания протокола (http://, https://)"
								>
									<b-form-input
										id="input-send-api-host"
										v-model="config_new.send_api_host"
										placeholder="Например: security.kdt.kz"
										type="text"
										required
									></b-form-input>
								</b-form-group>
								
								<b-form-group
									id="input-group-send-api-prefix"
									label="Префикс к адресу сайта с названием API функции:"
									label-for="input-send-api-prefix"
								>
									<b-form-input
										id="input-send-api-prefix"
										v-model="config_new.send_api_prefix"
										placeholder="Например: /entity/app.model.visits.addVisitFromReader"
										type="text"
										required
									></b-form-input>
								</b-form-group>
								
								<b-form-group
									id="input-group-rfid-count"
									label="Количество RFID ридеров:"
									label-for="input-rfid-count"
								>
									<b-form-input
										id="input-rfid-count"
										v-model="config_new.rfid_count"
										placeholder="Например: 1"
										type="number"
										readonly
									></b-form-input>
								</b-form-group>
								
								<div v-for="(rfid, index) in config_new.rfid_groups">
									<div class="d-flex justify-content-between align-items-center">
										<h4>RFID_{{index + 1}}</h4>
										<div class="my-2" v-if="config_new.rfid_groups.length > 1">
											<b-button variant="danger" @click="onConfigRfidGroupRemove(index)">
												<i class="fa fa-trash"></i>
											</b-button>
										</div>
									</div>
									
									<b-form-group
										:id="'input-group-rfid'+index+'-id'"
										label="ID ридера:"
										:label-for="'input-rfid'+index+'-id'"
									>
										<b-form-input
											:id="'input-group-rfid'+index+'-id'"
											v-model="rfid.id"
											:placeholder="'Например: '+index"
											type="number"
											min="1"
											required
										></b-form-input>
									</b-form-group>
									
									<b-form-group
										:id="'input-group-rfid'+index+'-ip'"
										label="IP ридера:"
										:label-for="'input-rfid'+index+'-ip'"
									>
										<b-form-input
											:id="'input-group-rfid'+index+'-ip'"
											v-model="rfid.ip"
											placeholder="Например: 192.168.1.250"
											type="text"
											required
										></b-form-input>
									</b-form-group>
									
									<b-form-group
										:id="'input-group-rfid'+index+'-port'"
										label="Порт ридера:"
										:label-for="'input-rfid'+index+'-port'"
									>
										<b-form-input
											:id="'input-group-rfid'+index+'-port'"
											v-model="rfid.port"
											placeholder="Например: 27011"
											type="number"
											min="0"
											required
										></b-form-input>
									</b-form-group>
									
									<b-form-group
										:id="'input-group-rfid'+index+'-version'"
										label="Режим работы ридера:"
										:label-for="'input-rfid'+index+'-version'"
									>
										<b-form-select
											:id="'input-rfid'+index+'-version'"
											v-model="rfid.version"
											:options="config_form.rfid_verson_options"
											required
										></b-form-select>
									</b-form-group>
									
									<b-form-group
										:id="'input-group-rfid'+index+'-default-path'"
										label="Дополнительный параметр, указывающий направление:"
										:label-for="'input-rfid'+index+'-default-path'"
										description="1: вход, 0: выход, -1: не определено"
									>
										<b-form-input
											:id="'input-group-rfid'+index+'-default-path'"
											v-model="rfid.default_path"
											placeholder="Например: -1"
											type="number"
											min="-1"
											max="1"
											required
										></b-form-input>
									</b-form-group>
								</div>
								<div class="text-center">
									<b-button variant="primary" @click="onConfigRfidGroupAdd()">
										<i class="fa fa-plus"></i>
									</b-button>
								</div>
							</b-form>
						</b-overlay>
					</b-tab>
				</b-tabs>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'RFIDApp',
	data: () => ({
		statusOverlay: false,
		isActive: null,
		isEnabled: null,
		txtStatus: '',
		
		txtAppLog: '',
		txtErrorLog: '',
		txtSendLog: '',
		txtRequestsQueue: '',
		
		configOverlay: false,
		config: {},
		config_new: {},
		config_form: {
			debug_log_level_options: [
				{text: '1: Ведется список действий', value: 1},
				{text: '0: Отключить логи', value: 0},
			],
			bool: [
				{text: 'Да', value: true},
				{text: 'Нет', value: false},
			],
			send_api_method_options: [
				'GET',
				'POST',
			],
			rfid_version: [
				{text: '0: Обработка 2 антенн (внешняя: 1; внутренняя: 2)', value: 0},
				{text: '1: Обработка 4 антенн (внешние: 1, 2; внутренние: 3, 4)', value: 1},
				{text: '2: Обработка 1 антенны (без определения направления)', value: 2},
			],
		},
	}),
	methods: {
		getStatus(){
			this.statusOverlay = true;
			fetch('/api/rfidapp/status')
				.then(stream => stream.json())
				.then(ret => {
					this.txtStatus = ret.status;
					this.isActive = ret.isActive;
					this.isEnabled = ret.isEnabled;
					
					setTimeout(() => {
						this.statusOverlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
		setStart(){
			this.statusOverlay = true;
			fetch('/api/rfidapp/start')
				.then(stream => stream.json())
				.then(ret => {
					setTimeout(() => {
						this.getStatus();
					}, 5000);
				})
				.catch(error => console.error(error));
		},
		setRestart(){
			this.statusOverlay = true;
			fetch('/api/rfidapp/restart')
				.then(stream => stream.json())
				.then(ret => {
					setTimeout(() => {
						this.getStatus();
					}, 5000);
				})
				.catch(error => console.error(error));
		},
		setStop(){
			this.statusOverlay = true;
			fetch('/api/rfidapp/stop')
				.then(stream => stream.json())
				.then(ret => {
					setTimeout(() => {
						this.getStatus();
					}, 5000);
				})
				.catch(error => console.error(error));
		},
		getConfig(){
			this.configOverlay = true;
			fetch('/api/rfidapp/config')
				.then(stream => stream.json())
				.then(ret => {
					this.config = ret.config;
					this.config_new = ret.config;
					
					setTimeout(() => {
						this.configOverlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
		onConfigRfidGroupAdd(){
			this.config.rfid_groups.push({
				id: '',
				ip: '192.168.1.250',
				port: 27011,
				version: 0,
				default_path: -1,
			});
			this.config.rfid_count = this.config.rfid_groups.length;
		},
		onConfigRfidGroupRemove(index){
			this.config.rfid_groups.splice(index, 1);
			this.config.rfid_count = this.config.rfid_groups.length;
		},
		onConfigSave(event){
			event.preventDefault();
			
			this.configOverlay = true;
			fetch('/api/rfidapp/config', {
				method: 'POST',
				body: JSON.stringify({
					config: this.config_new,
				}),
				headers: {
					'Content-Type': 'application/json'
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					this.config = this.config_new;
				}
				setTimeout(() => {
					this.configOverlay = false;
				}, 500);
			}).catch(error => console.error(error));
		},
		onConfigSetDefault(event){
			event.preventDefault();
			
			this.configOverlay = true;
			fetch('/api/rfidapp/config-def')
				.then(stream => stream.json())
				.then(ret => {
					this.config_new = ret.config;
					
					setTimeout(() => {
						this.configOverlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
	},
	beforeMount(){
		this.getStatus();
		this.getConfig();
	},
};
</script>