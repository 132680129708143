import Vue from 'vue'
import Vuex from 'vuex'

import {getCookie, setCookie, deleteCookie, localStorageSet, localStorageGet, localStorageRemove} from '../lib.js';

let store = function(params){
	params["store"] = new Vuex.Store({
		state: {
			
		},
		actions: {
			
		},
		mutations: {
			
		},
		getters: {
			
		},
		modules: {
			page: {
				namespaced: true,
				state: () => ({}),
				mutations: {},
			},
		},
	});
	
	return params;
};

/* Init data */
store.init = function(params){
	let store = params["store"];
	
	/* set name */
	//store.state.page.Layout.modalOpen = false;
	//store.state.page.Island.pageTitle = "Страница";
	
	return params;
}

/* Extends vue */
store.install = function(Vue, options){
	Vue.prototype.storeCommit = function(action, params){
		let arr = this.namespace.concat(action.split("/"));
		this.$store.commit(arr.join("/"), params);
	};
	
	Vue.prototype.storeDispatch = function(action, params){
		let arr = this.namespace.concat(action.split("/"));
		this.$store.dispatch(arr.join("/"), params);
	};
	
	Vue.prototype.getModel = function(){
		let arr = this.namespace.slice();
		let obj = this.$store.state;
		while(arr.length != 0){
			let key = arr.shift();
			if(obj[key] == undefined){
				obj = null;
				break;
			}
			obj = obj[key];
		}
		return obj;
	};
};

export default store;