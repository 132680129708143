export function microtime(get_as_float){
	// discuss at: http://phpjs.org/functions/microtime/
	// original by: Paulo Freitas
	// example 1: timeStamp = microtime(true);
	// example 1: timeStamp > 1000000000 && timeStamp < 2000000000
	// returns 1: true
	let now = new Date().getTime() / 1000;
	let s = parseInt(now, 10);
	return (get_as_float) ? now : (Math.round((now - s) * 1000) / 1000) + ' ' + s;
}
export function mt_rand(min, max){
	return Math.floor(Math.random() * (max - min) + min);
}
export function genId(){
	return Math.floor(microtime(1) * 10000)+''+mt_rand(10, 99);
}
// склонение слов (метод 1)
export function decl1(intval, expr){
	if(intval == null || typeof(intval) == 'undefined') intval = 1;
	if(expr == null || typeof(expr) == 'undefined') expr = ['день', 'дня', 'дней'];
	
	intval = parseInt(intval);
	let count = intval % 100;
	let result = '';
	if(count >= 5 && count <= 20){
		result = expr[2];
	} else {
		count = count % 10;
		if(count == 1){
			result = expr[0];
		} else if(count >= 2 && count <= 4){
			result = expr[1];
		} else {
			result = expr[2];
		}
	}
	return result;
}
// склонение слов (метод 2)
export function decl2(intval, expr){
	if(intval == null || typeof(intval) == 'undefined') intval = 1;
	if(expr == null || typeof(expr) == 'undefined') expr = ['день', 'дня', 'дней'];
	
	let k = intval % 10 == 1 && intval % 100 != 11 ? 0 : (intval % 10 >= 2 && intval % 10 <= 4 && (intval % 100 < 10 || intval % 100 >= 20) ? 1 : 2);
	return expr[k];
}
// ex: alert(dateDiff('2013-01-10 00:10', '2013-01-11 00:09'));
// ex: alert(dateDiff('2013-04-10', '2013-10-20'));
// date1 - ot, date2 - do
export function dateDiff(date1, date2){
	date1 = new Date(date1);
	date2 = new Date(date2);
	
	let milliseconds = date2.getMilliseconds() - date1.getMilliseconds();
	
	if(milliseconds < 0){
		milliseconds += 1000;
		date2.setSeconds(date2.getSeconds() - 1);
	}
	
	let seconds = date2.getSeconds() - date1.getSeconds();
	
	if(seconds < 0){
		seconds += 60;
		date2.setMinutes(date2.getMinutes() - 1);
	}
	
	let minutes = date2.getMinutes() - date1.getMinutes();
	
	if(minutes < 0){
		minutes += 60;
		date2.setHours(date2.getHours() - 1);
	}
	
	let hours = date2.getHours() - date1.getHours();
	
	if(hours < 0){
		hours += 24;
		date2.setDate(date2.getDate() - 1);
	}
	
	let days = date2.getDate() - date1.getDate();
	
	if(days < 0){
		days += new Date(date2.getFullYear(), date2.getMonth() - 1, 0).getDate() + 1;
		date2.setMonth(date2.getMonth() - 1);
	}

	let months = date2.getMonth() - date1.getMonth();
	
	if(months < 0){
		months += 12;
		date2.setFullYear(date2.getFullYear() - 1);
	}
	
	let years = date2.getFullYear() - date1.getFullYear();
	
	return {
		years: years,
		months: months,
		days: days,
		hours: hours,
		minutes: minutes,
		seconds: seconds,
		milliseconds: milliseconds
	};
}
export function deleteCookie(name){
	setCookie(name, "", -1);
}
export function setCookie(name, value, days){
	let expires;
	if(days){
		let date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toGMTString();
	}
	else expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}
export function getCookie(name){
	let nameEQ = name + "=";
	let ca = document.cookie.split(';');
	for(let i = 0;i < ca.length; i++){
		let c = ca[i];
		while(c.charAt(0) == ' ') c = c.substring(1, c.length);
		if(c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

export function localStorageSet(key, value, ttl = 60 * 60 * 24 * 1000){
	const now = new Date();

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item));
}
export function localStorageGet(key){
	const itemStr = localStorage.getItem(key);

	// if the item doesn't exist, return null
	if(!itemStr){
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	// compare the expiry time of the item with the current time
	if(now.getTime() > item.expiry){
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}
export function localStorageRemove(key){
	localStorage.removeItem(key);
}