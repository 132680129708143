<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="light" fixed="top">
			<div class="container">
				<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
					<b-navbar-brand :href="href" @click="navigate">
						<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="45" height="30" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" viewBox="0 0 14588 9288" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><polygon style="fill:#CF2124" points="0,4644 4644,0 9288,4644 4644,9288 "/><polygon style="fill:#5B80BF" points="5300,4644 9944,0 14588,4644 9944,9288 "/><polygon style="fill:#7E2F85" points="5306,4643 7293,2650 9280,4643 7293,6637 "/><polygon style="fill:#FEFEFE;fill-rule:nonzero" points="7293,5050 7299,5050 7736,3888 8057,3888 8057,5399 7805,5399 7805,4313 7799,4313 7383,5399 7210,5399 6787,4292 6780,4294 6780,5399 6528,5399 6528,3888 6859,3888 "/><path style="fill:#FEFEFE;fill-rule:nonzero" d="M2830 5860l0 -2517 697 0c241,0 429,56 565,169 135,113 203,283 203,509 0,117 -29,222 -88,313 -59,91 -141,158 -246,200 146,24 259,94 338,209 79,114 119,249 119,405 0,232 -67,409 -200,530 -133,122 -316,182 -551,182l-837 0zm351 -1173l0 907 486 0c127,0 225,-38 294,-115 69,-77 104,-186 104,-329 0,-139 -34,-249 -101,-332 -68,-83 -162,-127 -284,-131l-19 0 -480 0zm0 -253l400 0c113,-4 201,-40 265,-110 64,-69 96,-169 96,-296 0,-139 -35,-243 -105,-312 -71,-69 -174,-104 -310,-104l-346 0 0 822z"/><path style="fill:#FEFEFE;fill-rule:nonzero" d="M11708 5112l3 10c5,248 -64,440 -208,578 -142,137 -339,205 -589,206 -258,-1 -464,-83 -617,-249 -153,-165 -230,-397 -230,-695l0 -699c0,-298 75,-530 227,-697 151,-167 353,-250 608,-250 257,0 457,66 601,199 145,133 215,328 210,584l-4 10 -337 0c0,-180 -39,-312 -117,-397 -79,-84 -196,-127 -353,-127 -155,0 -274,58 -358,174 -84,116 -126,282 -126,499l0 704c0,219 43,386 129,502 87,117 209,175 367,175 152,0 267,-41 343,-125 75,-84 114,-218 114,-402l337 0z"/></g></g></svg>
						<span class="d-inline-block d-xl-none d-lg-none">BMC Device Panel</span>
					</b-navbar-brand>
				</router-link>
				<!--<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->
				<b-navbar-toggle target="nav-collapse">
					<template #default="{ expanded }">
						<b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
						<b-icon v-else icon="chevron-bar-down"></b-icon>
					</template>
				</b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<router-link custom :to="{path: '/status'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Статус</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/wifi'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">WiFi</b-nav-item>
						</router-link>
					</b-navbar-nav>
					<b-navbar-nav class="ml-auto">
						<router-link custom :to="{path: '/nfcapp'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">NFC App</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/nfcfingerprintapp'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">NFC FingerPrint App</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/rfidapp'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">RFID App</b-nav-item>
						</router-link>
					</b-navbar-nav>
				</b-collapse>
			</div>
		</b-navbar>
		<div>
			<router-view/>
		</div>
		<footer class="py-3 bg-dark">
			<div class="container text-center">
				<div class="row">
					<div class="col-md-12">
						<div class="copyright text-white">
							<a class="text-white" href="https://bmconsult.kz" target="_blank">Разработано "BenchMark Consulting"</a> &copy; 2022
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<style lang="css">
body {
	margin-top: 58px;
}
.navbar-logo {
	width: 32px;
	height: 32px;
}
footer .footer-menu a {
	margin: 0 10px;
	text-decoration: underline;
	color: #fff;
}
footer .footer-menu a:hover {
	text-decoration: none;
}
</style>

<script>
//import { microtime, mt_rand, genId } from '../../lib.js';

//import QuickLinks from '../components/QuickLinks.vue';

export default {
	name: 'Layout',
	props: ['namespace'],
	data: () => ({
		
	}),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {};
		}
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {
				//"QuickLinks": QuickLinks.buildStore(),
			},
			mutations: {},
			actions: {},
		}
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
	},
	methods: {
		
	},
	components: {
		//QuickLinks,
	},
}
</script>
