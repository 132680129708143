<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						WiFi Edit
						<div>
							<router-link custom :to="{path: '/wifi'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-button :href="href" @click="navigate">Назад</b-button>
							</router-link>
						</div>
					</h3>
				</template>
				<b-overlay :show="overlay" rounded="lg" variant="white">
					<b-form @submit="onSave">
						<div class="d-flex justify-content-between align-items-center">
							<code>/etc/NetworkManager/system-connections/{{oldssid.replaceAll(' ', '\\ ')}}</code>
							<div class="my-2">
								<b-button variant="danger" class="mr-1" @click="onDelete()">Удалить</b-button>
								<b-button variant="primary" type="submit">Сохранить</b-button>
							</div>
						</div>
						
						<b-form-group
							id="input-group-id"
							label="ID:"
							label-for="input-id"
						>
							<b-form-input
								id="input-id"
								v-model="id"
								type="number"
								readonly
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-ssid"
							label="Название WiFi сети (SSID):"
							label-for="input-ssid"
						>
							<b-form-input
								id="input-ssid"
								v-model="ssid"
								placeholder="Например: Wifi1"
								type="text"
								required
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-password"
							label="Пароль от WiFi сети:"
							label-for="input-password"
						>
							<b-form-input
								id="input-password"
								v-model="password"
								type="text"
								required
							></b-form-input>
						</b-form-group>
					</b-form>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
//import { decl1, decl2 } from '../assets/js/scripts.js';
//import _ from 'lodash';

export default {
	name: 'WifiEdit',
	data: () => ({
		overlay: false,
		
		id: null,
		oldssid: '',
		ssid: '',
		password: '',
	}),
	methods: {
		loadData(id){
			this.overlay = true;
			fetch('/api/wifi-connections/'+id)
				.then(stream => stream.json())
				.then(item => {
					//item.datetime = new Date(item.ugmtime_public).format('d.m.Y');
					
					this.id = item.id;
					this.oldssid = item.ssid;
					this.ssid = item.ssid;
					this.password = item.password;
					
					setTimeout(() => {
						this.overlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
		
		onSave(event){
			event.preventDefault();
			
			this.overlay = true;
			fetch('/api/wifi-connections/'+this.id, {
				method: 'POST',
				body: JSON.stringify({
					ssid: this.ssid,
					password: this.password,
				}),
				headers: {
					'Content-Type': 'application/json'
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					this.oldssid = this.ssid;
				}
				setTimeout(() => {
					this.overlay = false;
				}, 500);
			}).catch(error => console.error(error));
		},
		
		onDelete(){
			if(confirm('Вы уверены?')){
				this.overlay = true;
				fetch('/api/wifi-connections/'+this.id, {
					method: 'DELETE',
				}).then(stream => stream.json()).then(ret => {
					if(ret.success){
						this.$router.push('/wifi');
					} else {
						setTimeout(() => {
							this.overlay = false;
						}, 500);
					}
				}).catch(error => console.error(error));
			}
		},
	},
	beforeMount(){
		this.loadData(this.$route.params.id);
	},
	computed: {
		model(){
			return this.getModel();
		},
	},
	components: {
		
	},
};
</script>