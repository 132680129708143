<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0">Статус</h3>
				</template>
				<b-overlay :show="statusOverlay" rounded="lg" variant="white">
					<b-list-group>
						<b-list-group-item class="d-flex justify-content-between align-items-center">
							Ethernet IP:
							<h5 class="mb-0">
								<b-badge variant="primary">{{device_ip}}</b-badge>
							</h5>
						</b-list-group-item>
					</b-list-group>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Status',
	data: () => ({
		statusOverlay: false,
		device_ip: '127.0.0.1',
	}),
	methods: {
		getStatus(){
			this.statusOverlay = true;
			fetch('/api/status')
				.then(stream => stream.json())
				.then(ret => {
					this.device_ip = ret.device_ip;
					
					setTimeout(() => {
						this.statusOverlay = false;
					}, 500);
				})
				.catch(error => console.error(error));
		},
	},
	beforeMount(){
		this.getStatus();
	},
};
</script>