<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						WiFi Edit
						<div>
							<router-link custom :to="{path: '/wifi'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-button :href="href" @click="navigate">Назад</b-button>
							</router-link>
						</div>
					</h3>
				</template>
				<b-overlay :show="overlay" rounded="lg" variant="white">
					<b-form @submit="onAdd">
						<div class="d-flex justify-content-between align-items-center">
							<code></code>
							<div class="my-2">
								<b-button variant="primary" type="submit">Добавить</b-button>
							</div>
						</div>
						
						<b-form-group
							id="input-group-ssid"
							label="Название WiFi сети (SSID):"
							label-for="input-ssid"
						>
							<b-form-input
								id="input-ssid"
								v-model="ssid"
								placeholder="Например: Wifi1"
								type="text"
								required
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-password"
							label="Пароль от WiFi сети:"
							label-for="input-password"
						>
							<b-form-input
								id="input-password"
								v-model="password"
								type="text"
								required
							></b-form-input>
						</b-form-group>
					</b-form>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
//import { decl1, decl2 } from '../assets/js/scripts.js';
//import _ from 'lodash';

export default {
	name: 'WifiAdd',
	data: () => ({
		overlay: false,
		
		id: null,
		ssid: '',
		password: '',
	}),
	methods: {
		onAdd(event){
			event.preventDefault();
			
			this.overlay = true;
			fetch('/api/wifi-connections', {
				method: 'POST',
				body: JSON.stringify({
					ssid: this.ssid,
					password: this.password,
				}),
				headers: {
					'Content-Type': 'application/json'
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					this.$router.push('/wifi/'+ret.id);
				} else {
					setTimeout(() => {
						this.overlay = false;
					}, 500);
				}
			}).catch(error => console.error(error));
		},
	},
	computed: {
		model(){
			return this.getModel();
		},
	},
	components: {
		
	},
};
</script>